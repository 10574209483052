import React, { useState } from 'react'
import { Select, Tag } from 'antd'
import chineseLanguageLoader from 'chinese-language-loader/lib/main.js'

import './Filter.css'

const DATE_REGEX = /^(\d{4}[-./]|)(\d{1,2})[-./](\d{1,2})$/

const convertedFilters = (filters) => {
  for (const i in filters) {
    let filter = filters[i]
    const results = DATE_REGEX.exec(filter)
    if (results && results.length === 4) {
      const year = results[1].length === 0 ? new Date().getFullYear() : results[1].slice(0, 4)
      const month = ('0' + results[2]).slice(-2)
      const day = ('0' + results[3]).slice(-2)
      filter = `${year}.${month}.${day}`
    } else {
      filter = chineseLanguageLoader(filter, { language: 'zh-TW' })
    }
    filters[i] = filter
  }
  return filters
}

const tagRender = (props) => {
  const { label, value, closable, onClose } = props
  const color = DATE_REGEX.test(value) ? '#E16B8C' : '#054286'
  return (
    <Tag className='Filter-tag' color={color} closable={closable} onClose={onClose}>
      {label}
    </Tag>
  )
}

const Filter = ({ defaultFilters, onFilter }) => {
  const [filters, setFilters] = useState(defaultFilters)
  const handleChange = (value) => {
    const convertedValue = convertedFilters(value)
    setFilters(convertedValue)
    onFilter(convertedValue)
  }

  return (
    <Select
      className='Filter card'
      dropdownClassName='Filter-dropdown'
      mode='tags' 
      placeholder='Find our love ❤︎' 
      defaultValue={defaultFilters}
      value={filters}
      tagRender={tagRender}
      onChange={handleChange}
    >
    </Select>
  );
}

export default Filter
