import React from 'react'

import './Item.css'

const Item = ({ message }) => {
  const date = `[Tokyo ${message.tokyoTime} / Pacific ${message.pacificTime}]`
  const messageRender = (message) => {
    const type = message.attachmentType ? `[${message.attachmentType.toUpperCase()}] ` : ''
    let trimmedText = message.text.trim()
    trimmedText = trimmedText.replace(/(http.*?)$/, `<a href=$1 target='_blank' rel='noopener noreferrer'>${type}$1</a>`)
    return trimmedText
  }
  return (
    <div className='Item card'>
      <div className='Item-avatar'>
        <div className={`Item-dot ${message.color}`}></div>
      </div>
      <div className='Item-content'>
        <div className='Item-info'>
          {`${message.name} ${date}`}
        </div>
        <div className='Item-text'>
          <div dangerouslySetInnerHTML={{
            __html: messageRender(message)
          }}>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Item
