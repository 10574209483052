import React, { useState, useEffect } from 'react'
import { List } from 'antd'

import Item from '../Item'
import Filter from '../Filter'

import './List.css'

const paginationConfig = {
  position: 'both',
  pageSize: 20,
  simple: true
}

// const defaultFilters = [ '2020.03.29', '愛你' ]
const defaultFilters = [ '愛你' ]
// const defaultFilters = []

const filteredData = (data, filters) => {
  for (const filter of filters) {
    if (/\d{4}[-.]\d{1,2}[-.]\d{1,2}/.test(filter)) {
      const time = filter.replace(/-/g, '.')
      data = data.filter(m => m.tokyoTime.startsWith(time) || m.pacificTime.startsWith(time))
    } else {
      data = data.filter(m => m.text.includes(filter))
    }
  }
  return data
}

const MessageList = ({ data }) => {
  const [messages, setMessages] = useState(filteredData(data, defaultFilters))

  const handleFilter = (filters) => {
    setMessages(filteredData(data, filters))
  }

  useEffect(() => {
    setMessages(filteredData(data, defaultFilters))
  }, [data]);

  return (
    <div className='List'>
      <Filter onFilter={handleFilter} defaultFilters={defaultFilters} />
      <List
        className='List-content'
        dataSource={messages}
        renderItem={m => <Item key={m.id} message={m} />}
        pagination={paginationConfig}
      />
    </div>
  );
}

export default MessageList
