import React, { useState, useEffect } from 'react'
import { Container, setConfiguration } from 'react-grid-system'
import chineseLanguageLoader from 'chinese-language-loader/lib/main.js'

import List from '../List'

import messages from '../../data/messages.json'
import messagesOOB from '../../data/messages-oob.json'

import './App.css'

setConfiguration({ 
  defaultScreenClass: 'sm', 
  gridColumns: 2, 
  gutterWidth: 24,
  containerWidths: [480, 720, 720, 720],
  breakpoints: [480, 720, 720, 720]
})

const dateString = (timestamp, timeZone) => {
  const date = new Date(parseInt(timestamp))
  let options = {
    year: 'numeric', month: '2-digit', day: '2-digit',
    hour: '2-digit', minute: '2-digit', second: '2-digit',
    hour12: false,
  }
  options.timeZone = timeZone
  return date.toLocaleString('zh-CN', options).replace(/\//g, '.')
}

const mappedMessages = (messages) => {
  const POL_ID = '4687022714'
  const ROKU_ID = '753461666042421250'
  const names = {
    [POL_ID]: 'Pol.',
    [ROKU_ID]: 'Roku'
  }
  const colors = {
    [POL_ID]: 'bg-ocean',
    [ROKU_ID]: 'bg-sakura'
  }

  return messages.map(m => {
    const id = m.message_create.sender_id
    const timestamp = m.created_timestamp
    const text = chineseLanguageLoader(m.message_create.message_data.text, { language: 'zh-TW' }).replace(/粵/, '粤')
    const attachment = m.message_create.message_data.attachment
    const attachmentType = attachment ? attachment.media.type : ''
    return {
      id,
      name: names[id],
      color: colors[id],
      timestamp,
      tokyoTime: dateString(timestamp, 'Asia/Tokyo'),
      pacificTime: dateString(timestamp, 'America/Los_Angeles'),
      text,
      attachmentType,
      image: attachmentType === 'photo' ? `${attachment.media.media_url}:large` : ''
    }
  })
}

const processedData = async (data) => {
  return mappedMessages(data).sort((a, b) => a.timestamp - b.timestamp)
}

const LIMIT = 1266
const TOTAL = messages.length

const fetchData = async (page, limit) => {
  const start = page * limit
  const end = start + limit
  const count = end >= TOTAL ? TOTAL : end
  const tempData = messages.slice(start, count)
  const data = await processedData(tempData)
  return data
}

let tempStack = []

const fetchStackData = async (data) => {
  tempStack = tempStack.concat(data).sort((a, b) => a.timestamp - b.timestamp)
  // if (tempStack.length === messages.length) {
  //   console.log(JSON.stringify(tempStack))
  // }
  return tempStack
}

const App = () => {
  const [ loadedData, setLoadedData ] = useState([])

  useEffect(() => {
    // const loadData = async (page, limit) => {
    //   const data = await fetchData(page, limit)
    //   const temp = await fetchStackData(data)
    //   // console.log('Data', temp.length, new Date().getTime())
    //   setLoadedData(temp)
    // }
    // const interval = 66
    // const max = Math.floor(TOTAL / LIMIT)
    // for (let i = 0; i <= max; i++) {
    //   const page = i
    //   setTimeout(() => {
    //     loadData(page, LIMIT)
    //   }, interval * i)
    // }
    setLoadedData(messagesOOB)
  }, [])

  const header = (
    <header className='App-header'>
      <h1>
        <a className='App-link' href={`/`}>
          <div>如<span className='sakura'>鯨</span></div>
          <div>向<span className='ocean'>海</span></div>
        </a>
      </h1>
    </header>
  )

  const description = (
    <div className='App-description'>
      <div>我們之間的 {loadedData.length} 句小情話。</div>
      <div>2020.04.02</div><br />
      <div>更新於 2023.01.23</div>
      {/* <div>2022.12.28 (51423)</div> */}
      {/* <div>2021.01.23 (32722)</div> */}
      {/* <div>2020.04.02 (19765)</div> */}
    </div>
  )

  const footer = (
    <footer>
      &copy; 2020-{new Date().getFullYear()} Crafted with <span className='sakura'>❤︎</span> by <span className='ocean'>Pol</span>
    </footer>
  )

  return (
    <Container className='App'>
      {header}
      <main>
        {description}
        <List data={loadedData} />
      </main>
      {footer}
    </Container>
  )
}

export default App
